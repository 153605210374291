::selection {
    background-color: $selection-bg-color;
    color: $black;
}

html, body, .main-content {
    height: 100%;
}

html, body, .main-content {
    font-family: $font-family;
}

body {
    height: 100%;
    margin: 0;
    font-size: 18px;
}

header, footer {
    a {
        color: $white;
        &:hover {
            color: $white;
        }
    }

    h3, h4 {
        color: $white;
    }
}

header {
    flex-shrink: 0;
    background-color: $primary-color;
    padding: 15px 20px;
    
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > a {
            display: flex; 
        }

        .header-navigation {
            margin-top: 0;
       
            .data-dictionary-download-link {
                display: flex;
                align-self: center;
                margin-right: 1.25em;
                padding-right: 5px;

                button {
                    padding: 10px;
                    i {
                        margin-right: 0.5em;
                    }
                }

                &:hover {
                    text-decoration: none;
                }
            }

            .item {
                font-size: 18px;

                .dropdown {
                    color: $white;
                    div {
                        color: $white;
                        padding: 5px;
                    }
                }

                a {
                    padding: 8px;
            
                    &.active {
                        background-color: $primary-color-mid;
                        transition: background-color .3s;
                    }
                }
            }
        }
    }
}

footer {
    flex-shrink: 0;
    background-color: $primary-color-mid;

    .container {
        padding: 20px 10px;

        .footer-grid {
            display: -ms-grid;
            display: grid;
            justify-content: center;
            width: 100%;
            -ms-grid-columns: 1fr 4fr 1fr;
            grid-template-columns: 1fr 4fr 1fr;
            font-size: 15px;
            font-weight: 400;
            letter-spacing: 0.3px;
            line-height: 18px;

            .footer-follow {
                -ms-grid-column: 1;
                grid-column: 1;
                -ms-grid-row: 1;
                grid-row: 1;

                h4 {
                    margin: 0.5em 0;
                    padding-top: 0.5em;
                    font-size: 15.5px;
                }

                .social-media-links a {
                    margin-right: 10px;
                    font-size: 18px;

                    &:hover {
                        opacity: .7;
                    }

                    i {
                        margin: 0;
                    }
                }

                .water-uk-logo {
                    margin-right: 5rem;
                }
            }

            .footer-links {
                -ms-grid-column: 2;
                grid-column: 2;
                -ms-grid-row: 1;
                grid-row: 1;

                .footer-links-inner {
                    display: flex;
                    justify-content: space-around;

                    ul {
                        margin: 0;
                        a {
                            font-size: 16px;
                        }
                    }
                }
            }

            .footer-capita-branding {
                -ms-grid-column: 3;
                grid-column: 3;
                -ms-grid-row: 1;
                grid-row: 1;

                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;

                h4 {
                    color: $white;
                    margin-top: 0;
                    margin-bottom: 0.5em;
                }
            }
        }
    }

    .footer-copyright {
        border-top: 1px solid $line-break-color;
        color: $white;

        .container {
            padding: 0 10px;
            p {
                padding: 5px 0;
                font-size: 13px;
            }
        }
    }
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    line-height: 2.8;
}

a {
    color: $secondary-color-dark;
    text-decoration: none;
    &:hover {
        color: $a-hover;
        text-decoration: underline;
    }
}

h1, h2, h3, h4, h5, h6 {
    color: $h-color;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
}

h4 {
    font-size: 16px;
}

h2 {
    font-size: 28px;
}

.main-content {
    min-height: 80vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 25px 25px;
}

.water-uk-logo {
    height: 53px;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

.water-uk-logo-mobile {
    display: none;
}

.error {
    color: $error-color;
}

.error-modal > .header {
    color: $error-color !important;
}

.warning {
    color: $warning-color;
}

.page-error {
    .error-message div.header, p {
        color: $error-color !important;
    }
}

.link-disabled {
    pointer-events: none;
    color: $link-disabled;

    &:hover {
        color: $link-disabled;
        text-decoration: none;
    }
}

.no-table-data {
    text-align: center;
}

.display-linebreak {
  white-space: pre-line;
}

.table-search {
    width: 20em;
    margin: 0 auto;
    margin-bottom: 2em;
}

.dropzone-input {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    align-items: center;
    padding: 100px 20px;
    border-color: $primary-color-dark;
    border-style: dashed;
    background-color: $data-upload-input-bg;
    margin-top: 1em;

   &:hover {
    border-color: $primary-color-mid;

    i.upload {
        color: $primary-color-mid;
    }
   }

   &:focus {
       outline :none;
       border-color: $primary-color-mid;
   }

   i.upload {
        font-size: 1.7rem;
        color: $primary-color-dark;
        margin-bottom: 1rem;
    }
}

.underline {
    text-decoration: underline;
}

.success {
    color: forestgreen;
}

.metrics-table {
    $font-size: 0.9em;
    .ui.celled.table tr th {
        font-size: $font-size;
    }
    .ui.celled.table tr td {
        font-size: $font-size;
    };
}

.table-no-results {
    padding: 1.5em;
}

.ie-notice {
    text-align: center;
    margin: 0 auto;
    width: 60%;
}

.response-message-container {
    display: flex;
    margin-bottom: 1em;
}