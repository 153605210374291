.ui.dimmer .ui.modal-loader.loader {
    color: black;

    &:before {
        border-color: rgba(0,0,0,.1);
    }
    &:after {
        border-color: #767676 transparent transparent;
    }
}
            
.error-container {
    h1 {
        margin-top: 2em;
    }
}

.loader-container {
    padding: 5rem;
}