@import '../../assets/styles/variables';

div.cookie-banner .cookie-message {
        background-color: #3CB9C5;
        color: $white;
        position: fixed;
        top: auto;
        bottom: 0;
        width: 100%;

        .cookie-content-wrapper {
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            width: 90%;
    
            .cookie-text {
                display: flex;
                align-items: center;
                justify-content: flex-start;
    
                button {
                    margin-left: 5rem;
                    margin-bottom: 0.5rem;
                }
            }
        }
    }