.edit-modal {
    .edit-modal-content {
        padding: 30px 50px !important;
    }
    .ui.grid {
        display: flex;
        margin-bottom: 1px;
    }
    .label-column {
        display: flex !important;
        align-items: center;
        .edit-modal-label {
            color: rgba(0,0,0,.87);
            font-size: 1.4rem;
            font-weight: 700;
            text-transform: none;
        }
    }

    .checkbox-field {
        display: flex !important;
        align-items: center;

        .field {
            display: flex;
        }
    }

    .edit-modal-error-message {
        .header {
            text-align: left;
        }
        .content {
            font-size: 1.4rem;
            line-height: 30px;
        }
    }
}