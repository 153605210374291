@import '../../assets/styles/variables';

.generic-full-page-loading {
    background-color: $primary-color;
    min-height: calc(100vh);
    flex: 1;

    h2 {
        color: $white;
        text-align: center;
    }

    .water-uk-branding {
        position: absolute;
        top: 20%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
    }

    .water-uk-logo-loading {
        margin: 0 auto;
        width: 200px;
        height: auto;
        margin-bottom: 2em;
    }

    div.generic-full-page-loading-error {
        margin-top: 2.5rem;
    }

    h1 {
        margin: 2.5rem auto 0 auto;
    }

    button.logout-btn {
        margin: 2.5rem auto 0 auto;
        display: flex;
    }
}

.ie-full-page-loading {
    background-color: $primary-color;
    flex: 1 0 auto;
    min-height: calc(100vh);

    h2 {
        color: $white;
        text-align: center;
    }

    .water-uk-branding {
        margin-top: 4rem;
        position: absolute;
        top: 20%;
        left: 50%;
        transform: translate(-50%, -50%);

        .water-uk-logo-loading {
            display: flex;
            margin: 2em auto 2rem auto;
            width: 200px;
            height: auto;
        }

        button.ie-loading-btn {
            display: flex;
            margin: 0 auto;
        }
    }

    .water-uk-logo-loading {
        display: flex;
        margin: 0 auto;
        width: 200px;
        height: auto;
        margin-top: 5em;
        margin-bottom: 2em;
    }
}