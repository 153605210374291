.user-management-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    h1 {
        text-align: center;
        margin-bottom: 1em;
    }

    .table-action-buttons button:nth-child(1) {
        margin-right: 0.5em;
    }

    button[type="submit"] {
        width: 160px;
        margin-top: 1em;
    }
}