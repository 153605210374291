.terms-and-conditions-container {
    display: flex; 
    flex-direction: column;
    flex: 1 0 auto;

    width: 70%;
    margin: 0 auto;

    h1 {
        text-align: center;
        margin-bottom: 1em;
    }

    textarea {
        display: flex;
        margin: 0 auto 2em auto !important;
        min-height: 38em !important;
        resize: none !important;
    }

    .checkbox {
        display: flex !important;
        margin: 0 auto 2em auto !important;
    }

    button {
        margin: 0 auto 2em auto !important;
        width: 10em;
    }
}