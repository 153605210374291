h1 {
    text-align: center;
    margin-bottom: 1em;
}

.data-upload-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 0 auto;
    margin-bottom: 2em;
}

.templates-heading {
    text-align: center;
    margin-bottom: 1em;
}