@import '../../assets/styles/variables';

.contact-page-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    padding: 0 17.5%;

    .title {
        font-weight: bold;
        text-align: center;
        margin-bottom: 1.5em;
    }

    h3 {
        color: $h-color;
        font-size: 1.3em;
        margin-top: 0.5em;
    }
    
    h4 {
        font-size: 1.1em;
    }

    li {
        line-height: 1.6;
        list-style-type: none;
        margin-bottom: 1em;
    }

    .developer-services-contact, .water-companies-contact, .notes-section {
        margin-bottom: 1em;
    }

    .notes-section {
        width: 65%;
    }
}