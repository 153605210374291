.resubmission-container {
    display: flex;
    justify-content: center;
    flex-direction: column;

    h1 {
        text-align: center;
        margin-bottom: 1em;
    }

    .resubmission-form {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1 0 auto;

        .dropzone-input {
            margin-bottom: 3em;
        }

        .resubmission-card {
            width: 50%;
            margin-top: 1em;

            h2 {
                margin-top: 0.5em;
                margin-bottom: 0.5em;
                text-align: center;
            }

            button {
                text-align: center;
                display: flex;
                margin: 0 auto;
            }
        }

        .form {
            min-width: 50%;

            textarea {
                margin-bottom: 2.5em;
            }
            
            button {
                display: flex;
                margin: 0 auto 2em auto;
            }
        }
    }
}

