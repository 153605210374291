@import '../../assets/styles/variables';

.custom-table-container {
    overflow: auto;
    width: 100%;
    .ui.input {
        width: 100%;
    }

    .view-definition, .view-companies {
        list-style-type: none;
        color: $secondary-color-dark;
        &:hover {
            cursor: pointer;
            color: $primary-color-mid;
        }
    }

    .view-definition {
        line-height: 1.5em;
    }

    .table-add-btn {
        margin-right: 1em !important;
        margin-bottom: 1em !important;
    }
}