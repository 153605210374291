.ui {
    font-family: $font-family !important;
}

.ui.form .field > label { 
    font-size: 1.3rem;
}

.ui.checkbox label {
    font-size: 1.2rem;
}

.ui.button {
    color: $white;
    background-color: $secondary-color-dark;

    &:hover, &:focus {
        color: $white;
        background-color: $primary-color-mid;
    }
}

.ui.modal>.content  {
    font-size: 1.4rem !important;
    overflow-wrap: break-word;
}

.ui.progress>.label {
    font-size: 1.2em;
}

.ui.progress.progress-bar > div.bar {
    background-color: $primary-color-mid;
}

.ui.attached.tabular.menu {
    font-size: 1.14285714rem;
}

header {
    .ui.secondary.menu .item {
        padding: 5px !important;
    }

    .ui.menu .item>i.dropdown.icon {
        margin: 0 0 0 0.75em;
    }

    .ui.menu .ui.dropdown.item .menu {
        background-color: $primary-color;
        a.item {
            color: $white !important;
            &:hover {
                background-color: $primary-color-mid !important;
            }
    
            &.active {
                color: $white !important;
                background-color: $primary-color-dark !important;
            }
        }
    }    
}

.ui.steps {
    .title {
        color: $secondary-color-dark !important;
    }
}

.ui.celled.table tr th.table-header {
    cursor: pointer;
}

i.small.icon.table-sort-icon {
    margin-left: 0.5em;
}

.ui.message>.close.icon {
    padding-left: 0.6rem;
}