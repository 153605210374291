@media(max-width: 1155px) {
    .contact-page-container .notes-section {
        width: 100%;
    }
}

@media(max-width: 990px) {

    header .container {
        flex-direction: column;

        .logo-container {
            text-align: center;
        }

        .header-navigation {
            margin-top: 20px;
            flex-direction: column;

            .data-dictionary-download-link {
                margin-right: 0;
                margin-bottom: 1.5rem;
                button {
                    font-size: 1rem;
                }
            }

            .item {
                margin: 0.5rem 0 !important;
            }
        }
    }

    footer .container .footer-grid {
        -ms-grid-columns: 1fr 4fr;
        grid-template-columns: 1fr 4fr;

        .footer-follow {
            -ms-grid-column: 1;
            grid-column: 1;
            -ms-grid-row: 1;
            grid-row: 1;
        }
        
        .footer-links {
            -ms-grid-column: 2;
            grid-column: 2;
            -ms-grid-row: 1;
            grid-row: 1;
        }

        .footer-capita-branding {
            align-items: flex-start;
            padding-top: 1em;
            -ms-grid-column: 1;
            grid-column: 1;
            -ms-grid-row: 2;
            grid-row: 2;
            align-items: flex-start;
            padding-top: 1em;
        }
    }
}

@media(max-width: 768px) {

    .main-content .data-upload-container .data-upload-card {

        .data-upload-form {
            flex-direction: column;
        }

        .data-upload-btn-field, .data-upload-additional-options {
            display: flex;
            flex-direction: column;
            margin-bottom: 2rem;
            button {
                width: 100%;
            }
        }

        .data-upload-btn-fields {
            flex-direction: column;
        }

        .data-upload-additional-options .resubmission-btn {
            margin-bottom: 1.75em;
        }
    }

    footer .container .footer-grid {

        .footer-follow {
            -ms-grid-column: 1;
            grid-column: 1;
            -ms-grid-row: 1;
            grid-row: 1;
        }

        .footer-links {
            -ms-grid-column: 1;
            grid-column: 1 / 3;
            -ms-grid-row: 2;
            grid-row: 2;

            .footer-links-inner {
                margin-top: 1em;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
            }
        }

        .footer-capita-branding {
            align-items: flex-start;
            padding-top: 1em;
            -ms-grid-column: 1;
            grid-column: 1;
            -ms-grid-row: 3;
            grid-row: 3;
            align-items: flex-start;
            padding-top: 1em;
        }
    }
}

@media(max-width: 560px) {
    footer .container .footer-grid {
        .water-uk-logo-mobile {
            display: block;
        }

        .footer-links {
            -ms-grid-column: 1 / 3;
            grid-column: 1 / 3;
            -ms-grid-row: 2;
            grid-row: 2;

            .footer-links-inner {
                margin-top: 1em;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
            }
        }

        .footer-capita-branding {
            padding-top: 1em;
            -ms-grid-column: 2;
            grid-column: 2;
            -ms-grid-row: 1;
            grid-row: 1;
            justify-content: flex-start;
            align-items: flex-end;
        }
    }

    .main-content {
        .login-container .login-card {
            min-width: 200px;
        }
        .password-reset-container .password-reset-card {
            min-width: 200px;
        }
    }
}