@import '../../assets/styles/variables';

.homepage-notice {
    width: 60%;
    margin: 1em auto;

    h3 {
        font-size: 1.5em;
        color: $secondary-color-dark;
        font-weight: 700;
        margin-bottom: 1.2em;
        text-align: center;
    }

    h4 {
        font-size: 1em;
    }

    p {
        font-size: 1.35rem;
    }

    ul {
        list-style-type: inherit;
        list-style-position: inside;
        li {
            line-height: inherit;
            font-size: 1.35rem;
        }
    }
}