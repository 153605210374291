.company-checkbox {
    display: flex;
    justify-content: center;

    .ui.input {
        justify-content: center;
    }
}

.reporting-periods-companies-modal-action {
    display: flex;
}