@import '../../assets/styles/variables';

.main-content .data-upload-container .data-upload-card {
    min-width: 50%;

    &.upload-complete {
        text-align: center;

        button {
            margin-top: 1em;
        }
    }

    .data-upload-btn-field, .data-upload-form {
        margin-top: 1rem;
    }

    .fields.data-upload-additional-options {
        display: flex;
        justify-content: flex-end;
    }

    div.qualitative-dmex-score-field {
        margin-left: auto !important;
    }

    .data-upload-progress-bar {
        margin-top: 1.5em;
        margin-bottom: 3.5em;
    }
}