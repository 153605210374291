button.download-btn {
    background:none!important;
     border:none; 
     padding:0!important;
     color:#069;
     cursor:pointer;

     &:hover {
         text-decoration: underline;
     }
}