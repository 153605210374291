.validation-container {

    .ui.card.validation-card {
        width: 100%;
        max-width: 750px;
        margin: 0 auto 3em auto;

        ul {
            padding: 0 2em 0 2em;
            list-style-type: disc;

            li {
                font-size: 1.1em;
            }
            margin-bottom: 2em;
        }

        button.resubmission-btn {
            display: flex;
            margin: 0 auto;
        }
    }

    h1 {
        margin: 1.2em;

        &.warning, &.error {
            margin: 0;
        }
    }


    .card {
        margin-bottom: 3em !important;
    }

    .review-data-table-container {
        margin-bottom: 2.5em;

    }

    .action-buttons {
        display: flex;
        justify-content: center;
        button {
            margin-right: 2em;
        }
    }
}

.ui.card.submission-cancelled-container {
    margin: 0 auto;
    width: 30%;
}

.data-upload-container .upload-cancelled {
    text-align: center;
    h2 {
        margin-bottom: 1em;
    }
}