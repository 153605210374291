$primary-color: #3CB9C5;
$primary-color-bright: mix(white, $primary-color, 40%);
$primary-color-mid: #119CA9;
$primary-color-dark: #006C78;

$secondary-color: #2B96DD;
$secondary-color: mix(white, $secondary-color, 40%);
$secondary-color-mid: #3D7299;
$secondary-color-dark: #155277;

$charcoal: #2F3739;
$charcoal-50: mix(white, $charcoal, 50%);
$charcoal-20: mix(white, $charcoal, 80%);
$charcoal-5: mix(white, $charcoal, 95%);

$white: #F8F8FF;
$black: #000000;
$error-color: #C8102E;
$warning-color: #B8860B;
$selection-bg-color: #CCE2FF;
$line-break-color: #8AD5DC;

$font-family: "Geomanist-Regular",Arial,Helvetica,sans-serif;

$h-color: #225C7F;
$a-hover: #1E70BF;
$data-upload-input-bg: #FAFAFA;
$link-disabled: rgba(40, 40, 40, 0.7);